/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  font-size: 14px;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  -webkit-animation: spin 2s linear infinite;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}